.select-tests-header {
  text-decoration: underline;
}
.select-tests-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.tick-mark {
  color: rgb(9, 221, 51); /* Change the color as desired */
}
.case .tick-mark {
  color: rgb(7, 7, 7); /* Change the color as desired */
}
.form-data-container {
  border: 2px solid #ccc;
  padding: 10px;
  margin: 20px;
}


.test-list-container {
  margin-top: 0px;
}

.select-tests-headerh4 {
  text-align: center;
  text-decoration: underline;
  

}

.select-tests-headerh4 {
  font-size: 15px;
  margin-top: 0%;
}

.select-tests-headerh2 {
  text-align: center;
  text-decoration: underline;
  

}

.select-tests-headerh2 {
  font-size: 20px;
  margin-top: 0%;
}

.test-list {
  list-style: none;
  padding: 0;
}
body {
  margin: 1px;
}

.test-list li {
  margin-bottom: 5px;
}
.border-double {
  border: 3px double black;
}
/* Default styles */
/* Add your existing CSS styles here */

/* Responsive styles */
@media screen and (max-width: 768px) {
/* Adjust the layout for smaller screens */
.form-container {
  width: 100%;
  margin: auto;
}

}
.responsive-image {
max-width: 100%;
height: auto;
}
.form-container {
  border: 4px double black; 
  padding: 20px;
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* Center the container */
  
}




.green {
color: rgb(9, 221, 51);
}

.case {

display: flex;
justify-content: space-between;
padding: 0;
}

.case li {
width: 45%; /* Adjust width as needed */
margin-bottom: 2px; /* Adjust margin as needed */
}
.re{
display: flex;

}
.resh{
  display: flex;
 
  
}
.resh strong{
  text-decoration: underline;

}

.signature{
display: flex;
justify-content: space-between;
font-size: 14px;

}
.container {
display: flex;
justify-content: center; /* Center horizontally */
align-items: center; /* Center vertically */
/* height: 100vh; */
 /* Set the height to the full viewport height */
}
/* 
.form-container {
width: 90%; 
max-width: 1000px; /

} */

@media (min-width: 768px) {
.form-container {
  width: 1200px;
}
}

@media (min-width: 992px) {
.form-container {
  width: 1200px; 
}
}

@media (min-width: 1200px) {
.form-container {
  width: 60%; /* Adjust the width for extra-large screens */
}
}

/* 45768890 */
.formdata2 {
/* border: 2px solid black;  */
padding: 10px;
border-radius: 10px;
width: 98%;
line-height: 25px;
margin: 0 auto;
margin-top: 0px;
}

.formdata2 ul {
list-style: none;
padding: 0;
margin: 0;
font-size: 10px;


}


.formdata2 li {
  border-bottom: 1px solid #090909ec; /* Set the border width and color */
  padding: 0px 24px; /* Add padding to top and bottom, and increase left and right padding */
 
}


.formdata2 li:last-child {
border-bottom: none;
}

.formdata2 li strong {
margin-right: 1px;
border-bottom: none; 

}

/* dgffjgghj */

.item strong {
  margin-right: 5px;
  font-size: 12px; /* Adjust margin-right as needed */
}



/* .form-data-container {
border: 2px solid black;
padding: 0px;
width: 80%;
margin: 0 auto;
} */

.form-data-header {
margin-top: 0; /* Remove default margin for header */
}

.form-data-list {
list-style: none; /* Remove bullet points */
padding: 0; /* Remove default padding */
margin: 0; /* Remove default margin */
}

.form-data-item {
margin-bottom: 10px; /* Add space between items */
}

.form-data-input {
border: none; /* Remove default border */
border-bottom: 1px solid #ccc; /* Add bottom border */
padding: 8px; /* Add padding for input fields */
width: 100%; /* Make width 100% */
box-sizing: border-box; /* Include padding in width calculation */
}
/* Formdata.css */

/* .form-data {
list-style: none;
display: flex;
justify-content: space-between;
padding: 0;

} */
.form-data ul {
list-style: none;
padding: 0;
margin: 0;
display: flex;
/* flex-wrap: wrap; */
}

.form-data li {
flex: 0 0 50%; /* Two fields in one row */
box-sizing: border-box;
}

.form-data li:nth-child(1),
.form-data li:nth-child(2) {
flex-basis: 50%; /* Full width for the first two items */
}


.im {
height: 120px; /* Decrease the height of the image container */
width: 170px;

/* Align content vertically in the center */
/* Decrease the height of the image container */

}

.responsive-image {
width: 100%;
height: 100%;
object-fit: cover; /* Maintain aspect ratio of the image */
}
/* Formdata.css */


.image-container1 {
border: 2px solid black; /* Add border with black color */
width: 46%; /* Ensure the container spans the full width */
}


.image-container1 h4{
text-align: center;
  }
  .image-container1 h3{
    text-align: center;
      }
      
.responsive-image {
width: 100%;
height: 100%;
object-fit: cover; /* Maintain aspect ratio of the image */
}
/* Formdata.css */

.head {
border: 2px solid black; /* Add border with black color */
padding:1px;
text-align: center; /* Center align text content */
}

.control ul {
list-style: none;
padding: 0;
margin: 0;
}

.control li {
margin-bottom: 10px;
}

.control input[type="text"] {
border: none;
border-bottom: 1px solid #000000;
padding: 8px;
width: 100%;
box-sizing: border-box;
}
/* Formdata.css */

/* Default styles */

.control {
margin-top: 20px; /* Add margin to separate from the heading */
}

.control ul {
list-style: none;
padding: 0;
margin: 0;
}

.control li {
margin-bottom: 10px; /* Add margin between list items */
}

.control li input[type="text"] {
border: none;
border-bottom: 1px solid #ccc; /* Add bottom border */
padding: 8px; /* Adjust padding */
width: 100%; /* Make width 100% */
box-sizing: border-box;
}

/* Media query for tablets and larger screens */
@media (min-width: 768px) {
.test {
  padding: 0px;
  /* Increase padding on larger screens */
}
}

/* Media query for desktops and larger screens */
@media (min-width: 1024px) {
.test {
  padding: 0px;
  /* Increase padding on larger screens */
}
}
/* Formdata.css */

.ct {
border: 2px solid black; /* Add border with black color */
padding: 20px;
}

.ct h3 {
margin-top: 0; /* Remove default margin for the heading */
}
/* Formdata.css */

.control {
display: flex;
/* flex-wrap: wrap;
justify-content: space-between; */
}

/* .control ul {
list-style: none;
padding: 0;
margin: 0;
width: 100%;
} */

.control li {
flex: 0 0 calc(33.33% - 10px);
/* margin-bottom: 10px; */
}

.control input[type="text"] {
border: none;
border-bottom: 1px solid #ccc;
/* padding: 8px; */
width: 100%;
box-sizing: border-box;
}

/* Media query for tablets and larger screens */
@media (min-width: 768px) {
.control li {
  flex: 0 0 calc(50% - 10px);
}
}
/* Formdata.css */

.ct {
display: flex;
flex-direction: column;
align-items: center; /* Center align the items horizontally */
}

.control {
border: 2px solid black; /* Add border with black color */
padding: 20px;
display: flex;
flex-wrap: wrap; /* Allow items to wrap */
}

.control ul {
list-style: none;
padding: 0;
margin: 0;
width: 100%;
}

.control li {
flex: 0 0 calc(50% - 10px); /* Two fields per row */
margin-bottom: 10px;
}

.control input[type="text"] {
border: none;
border-bottom: 1px solid #ccc; /* Add bottom border */
padding: 8px; /* Adjust padding */
width: 100%; /* Make width 100% */
box-sizing: border-box;
}


/* Media query for tablets and larger screens */
@media (min-width: 768px) {
.control li {
  flex: 0 0 calc(33.33% - 10px); /* Three fields per row */
}
}
/* hiwjdhwiuh */
.containertest {
display: flex;
align-items: center; /* Center items vertically */
flex-wrap: wrap; /* Allow items to wrap */
}

.test-requisition {
margin-bottom: 20px; /* Add space between test requisition and form */
}
@media (min-width: 768px) {
  .item {
    margin-top: 20px; /* Adjust margin-top as needed */
   left:0%;
  }
  }

.form-data {
border: 2px solid black; /* Add border with black color */

flex: 1; /* Take up remaining space */
}

/* .form-data ul {
list-style: none;
padding: 0;
margin: 0;
} */

  


.form-data input[type="text"] {
border: none;
border-bottom: 1px solid #ccc; /* Add bottom border */
padding: 8px; /* Adjust padding */
width: 100%; /* Make width 100% */
box-sizing: border-box;
}

/* Media query for tablets and larger screens */
@media (min-width: 768px) {
.containertest {
  justify-content: space-between; /* Spread items evenly */
}

.test-requisition {
  margin-bottom: 0; /* Remove margin bottom for larger screens */
}

.form-data {
  margin-left: 0px; /* Add space between test requisition and form */
}
}
/* gihgyulfuyg */
.image-container {
  display: flex;
  border: 2px solid black;
}

.image-container img{
  margin-top: 40px;
  width:90%;
  margin-left: 10px;
} 



.form-datadate {
  border: 2px solid black;
  padding: 5px;
   /* Optional: Add padding for better spacing */
   /* margin-left: 12px; */

}

.im img {
  max-width: 100%;
  height: auto;
 
  
}

.controlno {
  border: 2px solid black; /* Add border with black color */
  border-radius: 5px;
  padding: 8px;
  width: 98%;
  margin: 0 auto;
  margin-top: 2px;

}

.controlno ul {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.controlno li {
  
    border-bottom: 1px solid #090909; /* Set the border width and color */
    padding: 0px 50px; /* Add padding to top and bottom, and increase left and right padding */

}



.controlno li strong {
  margin-right: 10px; /* Add spacing between label and value */
  font-size: 12px;
}
.controlno  strong {
  margin-right: 10px; /* Add spacing between label and value */
  font-size: 12px;
}

.controlno ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between; /* Distribute space evenly */
}




.formdata3 {
  border: 2px solid black; /* Add border with black color */
  padding: 10px;
  width: 98%;
  /* line-height: 50px; */

  border-radius: 10px;
  margin-top: 2px;
 
}



@media screen and (max-width: 768px) {
  .form-container {
    /* padding: 1%;
    display: flex; */
    width: 163%;
    flex-direction: column;
    align-items: center;
  }
  .controlno li strong {
   /* Add spacing between label and value */
    font-size: 8px;
  }
}
.select-tests-header1 {
  /* font-size: 1.5rem; */
text-align: left;
  text-decoration: underline;
  
}
.tms{
  text-align: right;
    margin-right: 10%
}
.tm{
  text-align: right;
  /* margin-right: 90px; */
  font-size: 15px;
}
.formdata3d {
  border: 2px solid black; /* Add border with black color */
  padding: 2px;
  width: 100%;
  /* line-height: 50px; */
 height: 260px;
  border-radius: 10px;
  margin-top: 2px;
 
}
.formdata2d {
  /* border: 2px solid black;  */
  padding: 10px;
  border-radius: 10px;
  width: 96%;
  line-height: 25px;
  margin: 0 auto;
 
  /* margin-top: 20px; */
  }
  
  
  .formdata2d ul {
  list-style: none;
  font-size: 10px;
  padding: 0;
  margin: 0;
  
  }
  
  
  .formdata2d li {
    border-bottom: 1px solid #090909ec; /* Set the border width and color */
    padding: 0px 28px; /* Add padding to top and bottom, and increase left and right padding */
  }
  
  
  .formdata2d li:last-child {
  border-bottom: none;
  }
  
  .formdata2d li strong {
  margin-right: 1px;
  border-bottom: none; 
  
  }
  .tnd{
  margin-left: 70%;
  }
  .formdata3dl {
    border: 2px solid black; 
    padding: 10px;
    width: 98%;
    /* line-height: 50px; */
    margin: 0 auto;
height:110px;
    border-radius: 10px;
    margin-top: 2px;

   
  }
  .formdata3dl h6{
    text-align: center;
    line-height: 1px;
  }
/* 09876543234567890-responsive */
/* Responsive adjustments */


/* Keep existing responsive adjustments for larger screens */
/* Add your existing CSS styles here */



@media (min-width: 768px) {
  .form-container {
    width: 100%; /* Set container width to full screen on mobile devices */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}
.whether li{
  border:none;
  border-width: none;
  
  padding: 0px 0px; /* Add padding to top and bottom, and increase left and right padding */
}

.formdsri {
 
  flex-direction: column;
}

.row {
 
  flex-direction: column;
  align-items: center; /* Optional: Align items vertically */
}

.form-info, .form-details {
  flex: 1; /* Each takes up equal width */
  padding: 10px; /* Optional: Add padding */
}
.item {
  margin-top: 20px; /* Adjust margin-top as needed */
  margin-left: 10px;
}

/* Style for the list items */
.item li {
  list-style: none; /* Remove default list bullet */
}

/* Style for the list item links */
.item a {
  display: block;
  padding: 10px 15px; /* Adjust padding as needed */
  text-decoration: none; /* Remove default underline */
  color: #000; /* Set text color */
  border-bottom: 1px solid #ccc; /* Add bottom border */
  transition: background-color 0.3s ease; /* Add transition effect */
}

/* Style for the active list item link */
.item a.active {
  background-color: #007bff; /* Change background color for active item */
  color: #fff; /* Change text color for active item */
}

.en strong{
  text-decoration: underline;
  font-size: 10px;
}
.formdata3dt p{
  font-size: 10px;
  color: #000000;

}
.str strong{
  font-size: 12px;
}
.formdata2dl{
  border: 2px solid black; /* Add border with black color */
  padding: 2px;
  width: 100%;
  /* line-height: 50px; */
 height: 180px;
  border-radius: 10px;
  margin-top: 2px;
}
.formdata2dl strong{
  font-size: 10px;
}
.formdata2dl h5{
  font-size: 10px;
}
.formdata3dt{
  border: 2px solid black; /* Add border with black color */
  padding: 2px;
  width: 100%;
  /* line-height: 50px; */
 height: 380px;
  border-radius: 10px;
  margin-top: 2px;
}
@media only screen and (max-width: 600px) {
  /* Increase height for mobile view */
  .formdata3dt {
    height: 450px; /* Adjust height as needed for mobile view */
  }
}
/* Hide React App header */
.Header {
  display: none;
}

/* for print */
/* Add this media query at the end of your CSS */

@media print {
  .Header {
    display: block;
  }
 
  .hide-on-print {
    display: none;
  }
 

  /* .form-container,
  .form-data-container,
  .formdata2,
  .formdata3,
  .image-container1,
  .control,
  .containertest,
  .controlno,
  .ct,
  .formdata3d,
  .formdata2d,
  .formdata2dl,
  .formdata3dt {
    padding: 5px;
    margin: 5px;
  }


  .select-tests-headerh4,
  .select-tests-headerh2,
  .formdata2 ul,
  .control input[type="text"],
  .control li,
  .formdata2d ul,
  .form-data-input,
  .form-data-header,
  .test-list-container,
  .formdsri,
  .select-tests-header1,
  .tm,
  .formdata3dt p,
  .str strong,
  .formdata2dl strong,
  .formdata2dl h5 {
    font-size: 14px;
  }

  .image-container1 h4,
  .image-container1 h3 {
    font-size: 12px;
  }

  .im img {
    max-width: 80%;
    height: auto;
  }

  .form-container {
    width: 98%;
    max-width: 98%;
  }

  .form-data-container,
  .image-container1,
  .controlno ul,
  .formdata3d,
  .formdata2d ul,
  .formdata2dl,
  .formdata3dt {
    width: 100%;
  }

  .containertest,
  .control,
  .controlno {
    flex-wrap: wrap;
  }

  .control li,
  .controlno li {
    flex: 0 0 100%;
  } */
  @media print {
    /* Hide the print button */
    button {
      display: none !important;
    }
    
    /* Hide the back link */
    li:first-child {
      display: none !important;
    }
    .formdata3dt {
      width: 100%; /* Ensure content fits within the PDF container */
      height: auto; /* Allow content to expand vertically if needed */
      font-size: 10px; /* Adjust font size as needed */
    }
    
    .formdata3dt h6 {
      margin-bottom: 10px; /* Add some space below each heading */
    }
    
    .formdata3dt p {
      margin-bottom: 10px; /* Add some space below each paragraph */
      text-align: justify; /* Justify text for better readability */
    }
    .formdata2dl {
      width: 100%; /* Ensure content fits within the PDF container */
      height: auto; /* Allow content to expand vertically if needed */
      font-size: 12px; /* Adjust font size as needed */
    }
    
    .formdata2dl h4 {
      margin-bottom: 0px; /* Add some space below each heading */
    }
    
    .formdata2dl p {
      margin-bottom: 0px; /* Add some space below each paragraph */
      text-align: justify; /* Justify text for better readability */
    }
    
 /* container */
.formdata3d {
  width: 100%;
  height: auto; /* Let the content determine the height */
  font-size: 8px; /* Further decreased font size */
  line-height: 1.2; /* Adjusted line height */
  padding: 2px; /* Reduced padding */
  margin-bottom: 2px; /* Reduced bottom margin */
  page-break-inside: avoid; /* Ensure the container does not break within a page */
}

/* Other styles remain the same */

    
    .formdata3d h4 {
      margin-bottom: 2px; /* Reduced margin */
    }
    
    .formdata3d p {
      margin-bottom: 2px; /* Reduced margin */
      text-align: justify;
    }
    
    .formdata3d ul {
      list-style-type: none;
      padding: 0;
      margin: 0; /* Removed default margin */
    }
    
    .formdata3d li {
      margin-bottom: 2px; /* Reduced margin */
    }
    
    .formdata3d .whether {
      margin-bottom: 2px; /* Reduced margin */
    }
    
    .formdata3d .responsive-image {
      width: 100%;
      height: auto;
    }
    
  
    
    .formdata3d .tick-mark {
      margin-right: 2px;
    }
    
    .formdata3d .rem {
      margin-bottom: 2px;
    }
    
    .formdata3d .re {
      margin-bottom: 2px;
    }
    
    .formdata3d .signature {
      display: flex;
      justify-content: space-between;
    }
    
    .formdata3d .signature h3 {
      margin-bottom: 2px;
    }
    
    .formdata3d .signature div {
      flex: 1;
    }
    
/* customer */
.formdata3 {
  width: 98%;
  height: 100%; /* Adjust the height as needed */
  font-size: 10px;
  line-height: 22px;
}

.formdata3 h4 {
  margin-bottom: 5px;
}

.formdata3 p {
  margin-bottom: 5px;
  text-align: justify;
}

.formdata3 ul {
  list-style-type: none;
  padding: 0;
}

.formdata3 li {
  margin-bottom: 3px;
}

.formdata3 .str {
  margin-bottom: 5px;
}

.formdata3 .en {
  margin-bottom: 10px;
}

.formdata3 .responsive-image {
  width: 100%;
  height: auto;
}
/* .formdata3d .case {
  list-style-type: none;
  padding: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

.formdata3d .case li {
  margin-bottom: 2px;
}

.formdata3 .case {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.formdata3 .case li {
  margin-bottom: 3px;
} */

.formdata3 .tick-mark {
  margin-right: 5px;
}

.formdata3 .rem {
  margin-bottom: 5px;
}

.formdata3 .re {
  margin-bottom: 5px;
}

.formdata3 .signature {
  display: flex;
  justify-content: space-between;
}

.formdata3 .signature h3 {
  margin-bottom: 5px;
}

.formdata3 .signature div {
  flex: 1;
}
/* padding */
.formdata2d strong {
  /* display: inline-block; */
  width: 50px; /* Adjust the width as needed */
  font-weight: bold;
}
.formdata2d li {
  display: inline-block;
  width: 20px; /* Adjust the width as needed */
}

.formdata3dt p{
  font-size: 8px;
  color: #000000;

}


.image-container img{
  width: 90%;
  height: auto; /* Ensure the height adjusts based on the content */
margin-left: 10px;
}

.im img {
  width: 90%;
  height: 100px; /* Adjusted height */
  object-fit: contain; /* Ensures the image scales correctly within the height */
}

.image-container1 {
  margin: 0px 0;
}
.image-container img{
  margin-top: 10px;
  width:90%;
  margin-left: 10px;
} 
.item {
  font-size: 12px; /* Adjust font size if needed */
  line-height: 1.4; /* Adjust line height for better spacing */
}
/* control */
.controlno li {
  
  border-bottom: 1px solid #090909; /* Set the border width and color */
  padding: 0px 35px; /* Add padding to top and bottom, and increase left and right padding */

}



.controlno li strong {
margin-right: 10px; /* Add spacing between label and value */
font-size: 15px;
}
.controlno  strong {
margin-right: 10px; /* Add spacing between label and value */
font-size: 10px;
}


  }
  }

/* Define a class for the centered button container */
.centered-button-container {
  text-align: center;
  margin-top: 30px;
}

/* Style the button */
.centered-button-container button {
  background-color: rgb(1, 1, 60);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

/* Change button background color on hover */
.centered-button-container button:hover {
  background-color: rgb(7, 7, 181);
}
