
.form-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
 
  .form-title {
    text-align: center;
  }

  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #021d48;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  .containercontrol {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #ccc; /* Add border */
    padding: 20px; /* Add padding for spacing */
  }
  
  .form-groupcontrol,
  .form-group {
    flex-basis: calc(50% - 10px); /* Adjust the width to occupy half of the container */
    margin-bottom: 20px;
  }
  
  /* Add margin between the fields */
  .form-groupcontrol {
    margin-right: 20px;
  }
  
  /* Style for the labels */
  .form-groupcontrol label,
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Style for the input fields */
  .form-groupcontrol input,
  .form-group input {
    width: 100%; /* Take up full width */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .form-groupcontrol,
    .form-group {
      flex-basis: 100%; /* Make fields occupy full width on smaller screens */
      margin-right: 0; /* Remove right margin on smaller screens */
    }
  
    .form-groupcontrol {
      margin-right: 0;
    }
  }
  .form-data input[type="text"][name="date"] {
    width: calc(10% - 10px); /* Set width to 50% of the container */
  }
  /* Formdata.css */

.containercontr {
  border: 2px solid black; /* Add border with black color */
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.containercontri {
  border: 2px solid black; /* Add border with black color */
  padding: 20px;
  margin-top: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}


.form-group {
  flex: 0 0 calc(50% - 10px); /* Three fields per row on larger screens */
  margin-bottom: 10px; /* Add space between form groups */
  padding-right: 10px; /* Add space between form groups */
}

.form-group:nth-child(odd) {
  padding-right: 0; /* Remove padding from the last item in the row */
}

.form-group label {
  display: block; /* Ensure labels are displayed as block elements */
}

.form-group input[type="text"] {
  width: 100%; /* Set input width to 100% */
  padding: 8px; /* Adjust input padding */
  box-sizing: border-box; /* Include padding and border in the width */
}





.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
}

.containercontr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.select-tests-header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.select-tests-header h2 {
  font-size: 20px;
}

.select-tests-header h5 {
  font-size: 16px;
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .containercontr {
    flex-direction: column;
  }

  .form-group {
    width: 100%;
  }
}/* Default styles */

/* Add your existing CSS styles here */

/* Responsive styles */
@media (max-width: 600px) {
  .containercontr {
    flex-direction: column;
  }

  .form-group {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }

  .form-group:nth-child(odd) {
    padding-right: 0;
  }

  .form-data input[type="text"][name="date"] {
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    max-width: 800px;
  }
  @media screen and (max-width: 768px) {
    .form-container {
      width: 130%;
    flex-direction: column;
    align-items: center;
    }
  }
  .form-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .containercontr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-group {
    margin-bottom: 20px;
    flex: 0 0 calc(50% - 10px);
    padding-right: 10px;
  }

  .form-group:nth-child(odd) {
    padding-right: 0;
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-input,
  .form-textarea,
  .form-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .select-tests-header {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .select-tests-header h2 {
    font-size: 20px;
  }

  .select-tests-header h5 {
    font-size: 16px;
  }

  .form-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #01254d;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 30px;
  }

  .form-button:hover {
   
    background-color: #007bff;
  }
}

.form-inputi {
  width: 350px; /* Adjust the width as needed */
  height: 80px; /* Adjust the height as needed */
}




.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.error-message {
 
  color: red;

}
.datepicker-width {
  width: 100%;
}
.custom-datepicker {
  width: 10px /* Adjust the width as needed */
}
