.excel-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    /* margin: 0 auto; */
    padding: 20px;
  }
 
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #f2f2f2;
  }
  
  .search-container {
    margin-bottom: 20px;
  }
  
  .search-container input[type="text"] {
    padding: 8px;
    margin-right: 10px;
  }
  
  .search-container button {
    padding: 8px 36px;
    background-color: #021d48;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
  }
 
  .search-container button:hover {
    background-color: #007bff;
  }
  