
  /* General styles for the navigation bar */
   /* #nav1 {
    display: flex;
    background-color: #ffffff;
    width: 100%; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    padding: 1px 0; 
    box-shadow: 0 2px 5px rgba(230, 225, 225, 0); 
  }  */
   
  #nav1 {
    display: flex;
    align-items: center;
    padding: 10px;
  
    background-color: #021d48;
    color: rgb(255, 255, 255);
  }
  
  #nav1 img {
    width: 150px;
    height: 120px;
    margin-right: 20px;
  }
  
  #nav1 h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  #nav1 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    /* margin-top: 5px; */
    margin-bottom: 34px;
  }
  
  #nav1 li {
    margin-right: 10px;
  }
  
  #nav1 a {
    color: white;
    text-decoration: none;
  }
  
  #nav1 a:hover {
    text-decoration: underline;
  }
  




  
  /* Styling for the image container */
  .image {
    margin-bottom: 3px; /* Space below the image */
  }
  
  .image img {
    width: 100px; /* Fixed width */
    height: 90px; /* Fixed height */
  }
  
  /* Navigation list styles */
  .list {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    display: flex; /* Flexbox for horizontal layout */
    width: 100%; /* Full width */
    justify-content: space-around; /* Evenly space items */
    align-items: center; /* Center items vertically */
  }
  
  .list li {
    padding: 10px; /* Padding around each link */
  }
  
  .list li a {
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Dark grey color for text */
    font-weight: bold; /* Bold font for emphasis */
  }
  
  .list li a:hover {
    color: #a885f3; /* Blue color on hover */
  }
  
  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    #nav1 {
      flex-direction: row; /* Keep navigation in a single row */
      justify-content: space-between; /* Space out logo and menu */
      padding: 10px 1px; /* Adjust padding */
    }
  
    .image img {
      width: 60px; /* Smaller image on mobile */
      height: 60px; /* Smaller image on mobile */
    }
  
    .list {
      flex-direction: row; /* Maintain row layout for navigation */
      flex-grow: 1; /* Allow list to take up fill space */
      justify-content: flex-start; /* Align links to start */
      overflow-x: auto; /* Enable horizontal scrolling if needed */
      margin-left: 20px; /* Spacing from the logo */
    }
  
    .list li {
      white-space: nowrap; /* Prevent wrapping of text */
      padding: 10px 15px; /* Adjust padding */
    }
  
    .list li a {
      text-align: left; /* Align text to the left */
    }
  }
  
  
  
  .video-container {
    width: 130%;
    max-width: 100%; /* Set a maximum width if needed */
    margin: 0 auto; /* Center the slider horizontally */
  }
  
  .video-container img {
    width: 100%; /* Make the videos fill the container width */
    height: auto; /* Maintain aspect ratio */
    margin-top:100px ;
  }
  .video-container video{
    width: 100%; /* Make the videos fill the container width */
    height: auto; /* Maintain aspect ratio */
    margin-top:100px ;
  }

  
  /* Adjust slider arrows and dots as needed */
  .slick-prev,
  .slick-next {
    z-index: 1; /* Ensure arrows are on top of the videos */
  }
  
  .slick-dots {
    bottom: 10px; /* Adjust dot position */
  }
  
  
  
  /* .container {
    display: flex;
    justify-content: center; 
  } */
  
  /* .image1,
  .image2 {
    width: 50%;
     
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
       
    }
  
    .image1,
    .image2 {
      width: 100%;
    }
  } */
  .video-container {
    position: relative;
  }
  
  .slide-item {
    position: relative;
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    background-color: rgba(6, 6, 6, 0.277); /* Adjust the opacity and color as needed */
    padding: 20px;
    border-radius: 10px;
  }
  
  .text-overlay h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .text-overlay p {
    font-size: 16px;
    margin: 0;
  }
  
  
  
  .container1 {
    display: flex;
    flex-wrap: wrap; /* Allow boxes to wrap to the next line on smaller screens */
    justify-content: space-between;
    padding: 10px;
  }
  
  .box1 {
    flex: 0 0 calc(50% - 20px); /* Each box takes half of the container width minus the padding */
    border: 3px solid #0c0505;
    padding: 0px; /* Adjust padding */
    box-sizing: border-box;
    margin-bottom: 1px; 
    margin-top: 10px;/* Add some margin between boxes */
    background-color: #b0aeae;
    
  }
  
  .box1 img {
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Ensure images don't exceed their container width */
  }
  
  .box1 p {
    text-align: center;
    margin: 0;
  }
  
  @media screen and (max-width: 768px) {
    /* Media query for screens smaller than 768px (tablet and mobile) */
    .container1 {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items along the cross axis */
    }
  
    .box1 {
      flex: 0 0 calc(100% - 20px); /* Each box takes full width on smaller screens minus the padding */
      margin-bottom: 10px; /* Decrease margin between boxes */
    }
  }
  
  
  
  
  
  .head1 {
    max-width: 800px; /* Set a maximum width for the content */
    /* margin: 0 auto; */
     /* Center the content horizontally */
    padding: 20px; /* Add padding for spacing */
  }
  
  .head1 h5 {
    font-size: 18px;
    color: rgb(151, 159, 159); /* Adjust heading font size */
  }
  
  .head1 h1 {
    font-size: 36px; /* Adjust heading font size */
  }
  
  .head1 p {
    font-size: 16px; /* Adjust paragraph font size */
    word-wrap: break-word; /* Allow long words to break into multiple lines */
  }
  .head1 h3{
    text-align: left;
  }
  .about{
    display: flex;
     line-height: 40px;
  }
  .aboutimg{
    margin-top: 50px;
  }
  
  .footer {
    background-color: #021d48; /* Grey background */
    color: #fffdfd; /* White text */
    padding: 50px 20px; /* Add padding */
  }
  
  .footer-container {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Wrap items to next line if needed */
    /* Evenly space items */
  }
  
  .footer-section {
    flex: 1; /* Each section takes equal space */
    margin: 10px; /* Add some margin between sections */
    text-align: center; /* Center-align content */
  }
  
  .footer-section img {
    width: 80px; /* Adjust the width of the image */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Make the image a block element */
    margin: 0 auto 10px; /* Center the image and add some bottom margin */
  }
  
  .footer-section h4 {
    margin-bottom: 10px; /* Add bottom margin to headings */
  }
  
  .footer-section p {
    margin: 5px 0; /* Add some margin to paragraphs */
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .footer-section {
      flex-basis: 100%; /* Each section takes full width on smaller screens */
    }
  
    .footer-section img {
      width: 60px; /* Reduce image size on smaller screens */
    }
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .footer-section {
      flex-basis: 50%; /* Each section takes 50% of the width on smaller screens */
    }
  }
  
  
  
  
  .head2 {
    text-align: center;
    margin-bottom: 15px; /* Center-align the content */
  }
  
  .head2 p {
    width: 80%; /* Set the width of the paragraph */
    margin: 0 auto; /* Center the paragraph horizontally */
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .head2 p {
      width: 90%; /* Adjust the width for smaller screens */
    }
  }
  
  
  
  
  .head2 h2 {
    color: #0c0252; /* Grey color */
  }
  
  /* Media query for responsiveness */
  /* @media screen and (max-width: 768px) {
    h2 {
      
    }
  } */
  .about {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the content horizontally */
}

/* .head1 {
    max-width: 80%;
    margin-bottom: 20px; 
}

.aboutimg img {
    max-width: 100%;
    height: auto; 
}


@media screen and (min-width: 768px) {
    .about {
        flex-direction: row;
        justify-content: space-between; /
        align-items: flex-start; 
    }

  } */

 
  .about {
    display: flex;
    flex-direction: column;
}

.about-content {
    flex: 1; 
    padding: 0 20px; 
    margin-bottom: 20px; 
    width: 100%; /* Take up the full width of the container */
}

.aboutimg img {
    width: 100%; 
    height: auto; 
}

/* Media query for tablets and larger devices */
@media (min-width: 768px) {
    .about {
        flex-direction: row;
    }

    .about-content {
        margin-bottom: 0; 
        margin-right: 0px; 
        width: 70%; /* Adjust width as needed */
    }
}
li {
  display: inline;
  margin-right: 10px;
  padding-top: 5%;

}

a {
  text-decoration:underline;
  color: rgb(1, 49, 194);
}
.footer-section a{
  text-decoration:none;
  color: rgb(255, 255, 255);
}
.Buy {
  line-height: 35px;
}
.footer-section img{
  width:200;
  height: 100px;
}
/* --- */




/* 
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}



.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}


@media (max-width: 768px) {
  .menu-icon {
      display: block;
  }

  .nav-links {
      flex-direction: column;
      display: none;
      width: 100%;
  }

  .nav-links.show {
      display: flex;
  }

  .nav-links li {
      text-align: center;
  }
} */

.nav-header{
  display: flex;
}
#nav1 h1{
  margin-top: 40px;
}
#menu-toggle {
  display: none; 
 
}

@media only screen and (max-width: 600px) {
  .nav-header {
    justify-content: space-between; 
    display: flex;
  }
  #nav1 img {
    width: 100px;
    height: 120px;
    margin-right: 0px;
}
#nav1 h1{
  margin-top: 45px;
font-size: 15px;
}
  #menu-toggle {
    display: inline-block; 
    margin-right: 10px; 
  }
  .menuh1 {
    display: none;
  }
  #menu-options {
    display: none; 
    position: absolute; 
    top: calc(100% + 10px); 
    left: 0; 
    background-color: #04012a9c; 
    padding: 10px; 
    z-index: 1; 
    width: 350px;
 
  }

  #menu-options.show-menu {
    display: block; 
    
  }
  #nav1{
    height:100px;
   
  }

  #menu-options ul {
    list-style-type: none; 
    padding: 0; 
  }

  #menu-options ul li {
    margin-bottom: 10px; 
  }
  #menu-options ul {
    list-style-type: none; 
    padding: 0;
    display: block; 
  }

  .nav-container {
    position: relative; 
 
  }
  .menu-toggle {
    cursor: pointer;
    margin-top: 40px;
}


.hamburger {
    width: 25px;
    height: 3px;
    margin-left: 30px;
    background-color: #fffefe;
 
    transition: 0.4s;
    margin-top: 5px;
    margin-right: 20px;
}

.show-menu .hamburger:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.show-menu .hamburger:nth-child(2) {
    opacity: 0;
}

.show-menu .hamburger:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

}
@media only screen and (min-width: 601px) {
  .nav-header {
    justify-content: space-between; 
    display: flex;
  }
  #nav1 img {
    width: 200px;
    height: 120px;
    margin-right: 150px;
}
#nav1 h1{
  margin-top: 45px;
font-size: 20px;
margin-right: 100px;
}



  #menu-toggle {
    display: inline-block; 
    margin-right: 10px;
    
  }

  #menu-options {
    display: none; 
    position: absolute; 
    top: calc(100% + 10px); 
    right: 0; 
    background-color: #04012a9c; 
    padding: 10px; 
    z-index: 1; 
    width: 450px;
 
  }

  #menu-options.show-menu {
    display: block; 
    
  }
  #nav1{
    height:120px;
   
  }

  #menu-options ul {
    list-style-type: none; 
    padding: 0; 
  }

  #menu-options ul li {
    margin-bottom: 10px; 
  }
  #menu-options ul {
    list-style-type: none; 
    padding: 0;
    display: block; 
  }

  .nav-container {
    position: relative; 
 
  }
  .menu-toggle {
    cursor: pointer;
    margin-top: 40px;
}


.hamburger {
    width: 25px;
    height: 3px;
    margin-left: 30px;
    background-color: #fffefe;

    transition: 0.4s;
    margin-top: 7px;
    margin-right: 20px;
}

.show-menu .hamburger:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.show-menu .hamburger:nth-child(2) {
    opacity: 0;
}

.show-menu .hamburger:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}


}
