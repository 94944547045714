
.image {
    position: fixed;
    position: absolute;
    left: 10px;
    margin: 10px;
  
  }

  .nb {
    background-color: rgba(255, 255, 255, 0.716);
  }
 
  
  #nav1 {
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    border-color: #000000;
  
    justify-content: space-evenly;
    height: 15%;
    top: 0px;
  
  }
  
  li {
    display: inline;
    margin-right: 10px;
    padding-top: 5%;
  
  }
  
  a {
    text-decoration: none;
    color: rgb(9, 9, 9);
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  nav ul {
  
    right: 20%;
  }
  
  nav ul li {
    display: inline-block;
    margin: 0px 20px;
    line-height: 50px;
    text-align: left;
    justify-content: space-around;
  }
  
 
  .image-with-text-container img{
    margin-top: 5%;
  width: 100%; 
 }
  
  
  /* .button h5 {
    margin-top: 10px;
  } */
  .home nav{
      height:80px;
      background-color: black;
     }
     .home ul{
      display:flex;
      align-items:center;
      justify-content: space-between;
  
     }
     .home li{
      margin: 30px;
     } 
     
    .centered-image {
      max-width: 100%;
      max-height:100%}
      /* Add any additional styles for your image */
     
    
    .navbar {
      background-color:#010845; 
      color: #451496; 
      height: 60px; 
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8%;
    
    }
    
    .navbar-content {
      width: 100%;
      max-width: 1200px; /* Set your preferred max-width */
      /* padding: 0 20px; */
      text-align: center;
      color: #e1e8e4;
    }
    
    .navbar-text {
      font-size: 1.5rem; /* Set your preferred font size */
      /* Add any additional styles for your text */
    }
   
  .head h4{
    color: #000000;
    margin-left:20px ;
  
  
  }  
  .center-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .centered-image1 {
    max-width: 100%;
    max-height: 100%;
    /* Add any additional styles for your image */
  } 
  .pinkno head h4{
    color: #ffffff;
    margin-left:20px ; 
   
  
  
  
  }  
  .center-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .centered-image2 {
    max-width: 100%;
    max-height: 100%;
    /* Add any additional styles for your image */
  } 
  .p2{
    padding: 0 10px; 
    text-align: left;
    line-height: 30px;
  }
  .p1{
    text-align: left; 
    padding: 0 10px;
    line-height: 30px;
  }
   .image{
    height: 100%;
    width: 100%;
   }
   .image-container {
    opacity: 2;
    transition: opacity 10s ease-in-out;
    
    
  }
  .images{
    margin-top:9%;
  }
  .image-container.visible {
    opacity: 1;
  
  }
   .vi2 img{
    align-items: center;
    justify-content: center;
  
  } 
   
 
  .bottom-nav-bar {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;

    /* background-color: #333; */
    display: flex;
    justify-content: space-around;
    padding: 15px;
    background-image:url("https://image.slidesdocs.com/responsive-images/background/city-business-gray-cover-minimalist-style-powerpoint-background_74af1f58e9__960_540.jpg") ;
    /* background-image: url("https://img.freepik.com/free-vector/gradient-smooth-background_23-2148969006.jpg?w=996&t=st=1709183793~exp=1709184393~hmac=c377907467953ad1b5d5ecfd78607fae4ba65fc681c28abcc7e80f91b36c8d55");  */
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 100%; 
    bottom:0%;
  }
  
  
    /* .link-style.active {
      color: #00bcd4; 
    }  */
    .bottom-nav-bar{
      display: flex;
    }
  
   
    .pinkno{
      width: 30%;
      /* height: 1000px; */
      border-radius: 4px;
      background-color:#faf9fb57;
       border: 1px solid #022860; 
      display: flex;
      height: 30vh;
      width:60vh;
    
      /* margin-top: 20px; */
     }
     .pinkni{
      width: 100%;
  height: 800px;
  border-radius: 4px;
  background-color:#3e26b6;
  border: 1px solid #022358; 
  
  height: 27vh;
  margin: 2%;
     }
  
   
      .pinkni h1{
        color: #ffffff;
        text-align: center;
      }
      .pinkni h5{
        color: #85898d;
        text-align: center;
      }

      .pinkni h4{
        color: #ffffff;
        text-align: center;
      }
      .bottom-nav-bar{
        margin-top: 50px;
      }
  
      .defense {
        /* Adjust as needed */
        
      margin-top:3%;
      margin: 0 10px;
       display: flex;
       max-width: auto;
        
        /* padding: 0 20px; */
      }
      
      .responsive-paragraph {
        font-size: 16px; /* Default font size */
        text-align: justify;
        /* margin-top: 3%;
        margin-left: 3%; */
        
      }
      
      @media (min-width: 768px) {
        .responsive-paragraph {
          font-size: 18px; 
          line-height: 20px;/* Adjust font size for larger screens */
        }
      }
      
      @media (min-width: 1024px) {
        .responsive-paragraph {
          font-size: 18px;
          line-height: 32px; /* Adjust font size for even larger screens */
        }
      }
     .vi2 img{
      
        display: block; /* Display the image as a block-level element */
        margin: 0 auto; /* Automatically center the image horizontally */
      
     }
     /* shobha code */
    
     .sliderimg {
      width: 100%;
      max-width: 800px; /* Adjust max-width based on your design */
      margin: 0 auto;
    }
    
    .slide {
      position: relative;
      width: 100%;
      height: auto; /* Ensure images maintain aspect ratio */
    }
    
    .slide-image {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    
    /* Responsive styles */
    @media screen and (max-width: 768px) {
      .sliderimg {
        max-width: 100%; /* Make slider full width on smaller screens */
      }
    }
    